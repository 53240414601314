<template>
     <Layout>
         <PageHeader :title="title" :items="items"/>

         <div class="row">
             <div class="col-lg-7 px-0">
                 <div class="card">
                     <div class="card-body">
                         <h4 class="header-title font-weight-bold"> List of coupons</h4>
                         <div>
                             <lottie-loader :loading="showLoader" />
                         </div>
                         <p class="text-muted font-13 mb-4"></p>
                         <div class="row mb-md-2">
                             <div class="col-sm-12 col-md-6">
                                 <div id="tickets-table_length" class="dataTables_length">
                                     <label class="d-inline-flex align-items-center">
                                         Show&nbsp;
                                         <b-form-select
                                                 v-model="perPage"
                                                 size="sm"
                                                 :options="pageOptions"
                                         ></b-form-select
                                         >&nbsp;entries
                                     </label>
                                 </div>
                             </div>
                             <!-- Search -->
                             <div class="col-sm-12 col-md-6">
                                 <div
                                         id="tickets-table_filter"
                                         class="dataTables_filter text-md-right"
                                 >
                                     <label class="d-inline-flex align-items-center">
                                         Search:
                                         <b-form-input
                                                 v-model="filter"
                                                 type="search"
                                                 placeholder="Search..."
                                                 class="form-control form-control-sm ml-2"
                                         ></b-form-input>
                                     </label>
                                 </div>
                             </div>
                             <!-- End search -->
                         </div>
                         <!-- Table -->
                         <div class="table-responsive mb-0" id="table">
                             <b-table
                                     table-class="table table-centered w-100"
                                     thead-tr-class="bg-light"
                                     :items="ListOfCoupons"
                                     :fields="tableFields"
                                     responsive="sm"
                                     :per-page="perPage"
                                     :current-page="currentPage"
                                     :sort-by.sync="sortBy"
                                     :sort-desc.sync="sortDesc"
                                     :filter="filter"
                                     :filter-included-fields="filterOn"
                                     @filtered="onFiltered"
                             >
                                 <template #cell(name)="data">
                                     <span class="font-14 font-weight-bold text-dark">   {{ data.item.name}}</span>
                                 </template>
                                 <template #cell(discount)="data">
                                     <span class="font-14 font-weight-bold text-dark">   {{ data.item.discount}}</span>
                                 </template>
                                 <template #cell(dateCreated)="data">
                                    <span class="font-12 font-weight-bold text-dark">{{ data.item.dateCreated | moment("MMMM Do YYYY") }}</span>
                                 </template>
                                 <template #cell(code)="data">
                                     <span class="badge badge-danger p-1"  id="copyInput"  @click="CopyCouponCode"  style="cursor: pointer" >{{data.item.code}}</span>
                                     <span class="tooltip1" v-if="showTooltip" >copied</span>

                                 </template>


                                 <template #cell(status)="data">
                                      <span :class="{
                                        'text-success p-1': `${data.item.status}` === 'ACTIVE',
                                        'text-danger p-1': `${data.item.status}` ==='EXPIRED',
                                        'text-secondary p-1' : `${data.item.status}` === 'PENDING'
                                      }" class="badge"> {{data.item.status}}</span>
                                 </template>

                                 <template #cell(actions)="data">
<!--                                     <a class="text-danger mr-2" style="cursor: pointer"  href="#" @click="DeleteCoupons(data.item)" > <i class="mdi mdi-trash-can"></i>Delete</a>-->
                                     <a class="mr-2 btn btn-info" style="cursor: pointer"  href="#" @click="EditCoupon(data.item.id)"> <i class="mdi mdi-pencil-minus"></i>Edit</a>
                                 </template>
                             </b-table>
                         </div>
                         <div class="row">
                             <div class="col">
                                 <div
                                         class="dataTables_paginate paging_simple_numbers float-right"
                                 >
                                     <ul class="pagination pagination-rounded mb-0">
                                         <!-- pagination -->
                                         <b-pagination
                                                 v-model="currentPage"
                                                 :total-rows="rows"
                                                 :per-page="perPage"
                                         ></b-pagination>
                                     </ul>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div class="col-lg-5 ">
                 <div class="card">
                     <div class="card-body">
                         <h4 class="header-title font-weight-bold mb-3"> Create / Update Coupon</h4>

                         <form action="#" @submit.prevent="CreateOrUpdateCoupon">

                             <div class="form-group mb-3">
                                 <label for="name">Coupon name</label>
                                 <input
                                         class="form-control"
                                         v-model="coupons.name"
                                         type="text"
                                         id="name"
                                         name="name"
                                         placeholder="Enter coupon name"
                                         v-validate="'required'"
                                         :class="{'is-invalid': errors.has('name') }"

                                 />
                                 <span v-show="errors.has('name')" class="help text-danger">{{ errors.first('name') }}</span>
                             </div>


                             <div class="form-group mb-3">
                                 <label for="name">Description</label>

                                 <input
                                         class="form-control"
                                         id="location"
                                         name="location"
                                         type="text"
                                         v-model="coupons.description"
                                         placeholder=""
                                 />
                             </div>
                             <div class="form-group mb-0 text-center">
                                 <button class="btn btn-primary float-right" :class="coupons.id ? 'btn-warning' : 'btn-primary'" type="submit">
                                     {{AddOrUpdateCoupon}}
                                 </button>
                             </div>

                         </form>
                     </div>
                 </div>
             </div>
         </div>

     </Layout>

</template>

<script>
    import PageHeader from "@/components/Page-header";
    import LottieLoader from "../../../../components/LottieLoader";
    import Layout from "../../../layouts/main";
    import SweetAlertWrapper from "../../../../../sweetAlert";
    import BaseUrl from "../../../../components/constants";
    import ToastConfigAlert from "../../../../../ToastConfig";
    import VTooltip from 'v-tooltip';
    export default {
        name: "ManageCoupons",
        components : {
          PageHeader,
            LottieLoader,
            Layout,
            'tooltip': VTooltip
        },
        data(){
            return {
                showTooltip: false,
                couponCode : '',
                coupons : {
                    id:'',
                   name :'',
                   description:''
                },
                AddOrUpdateCoupon : 'Add coupon',
                showLoader : false,
                ListOfCoupons : [],
                ListOfCouponsRaw : [],
                title: "Manage Coupons",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "coupons",
                        active: true,
                    },
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",
                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"Name",
                        key: "name",
                        sortable: true,
                    },
                    {
                        label :"Code",
                        key: "code",
                        sortable: true,
                    },
                    {
                        label :"Coupon Status",
                        key: "status",
                        sortable: true,
                    },
                    {
                        label :"Discount in %",
                        key: "discount",
                        sortable: true,
                    },
                    {
                        label:"Date createed",
                        key: "dateCreated",
                        sortable: true,
                    },
                    {
                        key: "actions",
                        sortable: true,
                    },
                ],
            }
        },
        methods : {
            CopyCouponCode(){

                let copyText = document.getElementById("copyInput");
                let textArea = document.createElement("textarea");
                textArea.value = copyText.textContent;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("Copy");
                textArea.remove();

                this.showTooltip = !this.showTooltip;

                if (this.showTooltip) {
                    setTimeout(() => {
                        this.showTooltip = false;
                    }, 2000); // 5 seconds in milliseconds
                }

               // alert("text copied : "+ textArea.value)

            },
            EditCoupon(id){

            },
            getListOfCoupons() {

                BaseUrl.AxiosBearer.get("/store/orders/coupons").then((response) => {
                    this.showLoader = false
                    if(response.data.status) {
                        this.ListOfCoupons = []
                        this.ListOfCouponsRaw = []
                        this.ListOfCouponsRaw = response.data.payload

                        response.data.payload.forEach((data) => {
                            this.ListOfCoupons.push({
                                id : data.id,
                                name : data.name,
                                code : data.code,
                                status : data.status,
                                discount : data.discountInPercentage+'%',
                                dateCreated : data.createdAt
                            })
                        })
                    }
                    else{
                        this.$bvToast.toast(response.data.message,ToastConfigAlert)
                    }
                }).catch((error) => {
                    this.showLoader = false
                    this.$bvToast.toast(error, ToastConfigAlert)
                })

            },
            CreateOrUpdateCoupon() {

            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering

                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            clearCoupons() {
                this.coupons = {
                    name: '',

                }
                this.$validator.reset();
            },
        },
        created() {
            this.getListOfCoupons()
            this.sweetAlert = new SweetAlertWrapper()
        },
        computed: {
            rows() {
                return this.ListOfCoupons.length;
            },
        },
    }
</script>

<style>

    .tooltip1 {
        display: block;
        position: absolute;
        background-color: black !important;
        color: #fff;
        padding: 6px;
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .tooltip1.active {
        display: block;
    }

</style>